import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import mapboxgl, { Map, MapMouseEvent } from 'mapbox-gl'
import styled from '@emotion/styled'
import { ChildrenProp } from '../../lib/componentProps'
import { DEFAULT_MAP_LOCATION, LatLngObj } from '../../lib/geo'
import { appConfig } from '../../appConfig'
import { useThemeMode } from '../providers/ThemeProvider'

mapboxgl.accessToken = appConfig.mapboxToken

const MapContainer = styled('div')`
    flex: 1 1;
`

export const MapContext = createContext<Map | null>(null)

const getMapboxStyle = (isDark: boolean) =>
    isDark ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/streets-v11'

type Props = {
    onClick?: (e: MapMouseEvent) => void
    defaultLocation?: LatLngObj
    defaultZoom?: number
    onMapLoad?: (map: Map) => void
} & ChildrenProp

export const MapboxMap: React.FC<Props> = ({
    children,
    onClick,
    defaultLocation = DEFAULT_MAP_LOCATION,
    defaultZoom = 10,
    onMapLoad,
}) => {
    const { isDarkMode } = useThemeMode()
    const [loaded, setLoaded] = useState(false)
    const [map, setMap] = useState<Map | null>(null)
    useEffect(() => {
        if (map && onClick) {
            map.on('click', e => {
                onClick(e)
            })
        }
    }, [map, onClick])
    const mapContainerRefCallback = useCallback(
        (node: HTMLDivElement) => {
            if (node) {
                if (map) {
                    map.remove()
                    setMap(null)
                }
                const newMap = new Map({
                    container: node,
                    style: getMapboxStyle(isDarkMode),
                    center: defaultLocation,
                    zoom: defaultZoom,
                })
                setMap(newMap)
                newMap.on('load', () => {
                    if (onMapLoad) {
                        onMapLoad(newMap)
                    }
                    setLoaded(true)
                })
            }
        },
        [isDarkMode],
    )
    return (
        <MapContainer ref={mapContainerRefCallback}>
            {loaded && map && <MapContext.Provider value={map}>{children}</MapContext.Provider>}
        </MapContainer>
    )
}
