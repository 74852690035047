import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import mapboxgl from 'mapbox-gl'
import React, { useEffect } from 'react'
import { useMapboxMap } from '../../hooks/useMap'
import { GeocoderResultObject } from '../../lib/geo'

type Props = {
    onResult?: (result: GeocoderResultObject) => void
}

const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    zoom: 16,
    marker: false,
    placeholder: 'Enter an address...',
    mapboxgl: mapboxgl as any,
    reverseGeocode: true,
})

export const MapGeocoder: React.FC<Props> = ({ onResult }) => {
    const map = useMapboxMap()
    useEffect(() => {
        if (!map.hasControl(geocoder as any)) {
            map.addControl(geocoder as any)
        }
        return () => {
            map.removeControl(geocoder as any)
        }
    }, [])
    useEffect(() => {
        if (onResult) {
            geocoder.on('result', e => {
                onResult(e)
            })
        }
    }, [onResult])
    return null
}
